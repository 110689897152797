export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Second Page',
        route: 'second-page',
        icon: 'FileIcon',
      },
      {
        title: 'Formuls',
        icon: 'SlackIcon',
        // Permission: 'formul_list',
        children: [
          {
            title: 'Insert',
            route: 'insert-formul',
            icon: 'PlusSquareIcon',
          },
          {
            title: 'Input',
            route: 'input-list',
            icon: 'ClipboardIcon',
          },
          {
            title: 'Tables',
            route: 'table-list',
            icon: 'SquareIcon',
          },
          // {
          //   title: 'Output',
          //   route: 'calculate-list',
          //   icon: 'EyeIcon',
          // },
          
        ],
      },
    ],
  },
]
